@import './variables.scss';

.mat-raised-button {
  font-size: 16px !important;
  height: 40px !important;
  border-radius: 10px !important;
  position: relative !important;
  top: 5px !important;

  &:not(.mat-button-disabled) {
    background-color: $navbar-light-blue !important;
  }

  &.mat-button-disabled:not([class*=mat-elevation-z]) {
    background: $platforms-icons-not-selected;
  }
}

.mat-stroked-button {
  border: 1px solid $popup-no-button-border !important;
  border-radius: 10px !important;

  &:hover:not(.mat-button-disabled) {
    border-color: $navbar-light-blue !important;
  }

  & .mat-button-focus-overlay {
    display: none;
  }

  &.mat-button-disabled:not([class*=mat-elevation-z]) {
    background: $platforms-icons-not-selected;
    box-shadow: unset;
    opacity: 0.5;
  }
}