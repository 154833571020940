$maximum-percentage: 100%;
$black: #000000;
$dark-blue: #030229;
$dark-blue-with-opacity: #030229B3;
$disableComponent: #0302294D;
$light-blue: #F7F7F8;
$light-grey: #DADADA;
$light-gray: #605BFF;
$white: #ffffff;
$no-records: #c7c7c7;
$scroll-thumb: #B3B3BF;
$navbar-light-blue: #605BFF;
$user-first-sign-box: #8F5BFF;
$header-devider: #8985FF;
$calendar-pages: #4285F4;
$add-or-edit-website-animation-duration: 0.5s;
$edit-blue: #5B93FF;
$edit-blue-background: rgba(91, 147, 255, 0.05);
//$red-background: rgba(245, 91, 93, 0.12);
$red-background: rgb(254, 235, 235);
$red-close-render-background: rgba(231, 29, 54, 0.05);
$red: #E71D36;
$red-color: #D10000;
$render-error-row: #FF5B5B;
$liveStatusColor: #3A974C;
$liveStatusBackground: rgba(58, 151, 76, 0.1);
$startedStatusColor: #F29339;
$startedStatusBackground: rgba(242, 147, 57, 0.1);
$canceledStatusColor: #FF5B5B;
$canceledStatusBackground: rgba(255, 91, 91, 0.1);
$redirectedStatusColor: #5B93FF;
$previewStatusColor: #5B93FF;
$redirectedStatusBackground: #EFF4FF;
$previewStatusBackground: rgba(91, 147, 255, 0.1);
$favorite: #FFD66B;
$box-shadow: rgba(0, 0, 0, 0.1);
$drop-shadow: #444;
$gray-counter: #898989;
$platforms-icons-not-selected: #EBE9FF;
$drag-drop-headers-text-color: #282828;
$drag-drop-headers-icons-color: #A0A0AE;
$drag-drop-modules-text-color: #7D7D90;
$menu-bright-grey-separator: #F3F3F3;
$button-hover-background: #7773FF;
$popup-no-button-border: #C7C6FF;
$menu-drag-drop-hover: #F5F4FF;
$available-modules-hover: #736FFF;
$icon-red-color: #FF5F5F;
$color-picker-default-background: #f1f3f4;
$color-picker-input-border: #D9D9D9;

$table-font-size: 14px;
$success-notification-color: #23C552;
$success-snackbar-background-color: #00AA07;
$notification-snackbar-background-color: #5B93FF;
$error-snackbar-background-color: #FF3131;
$subarrow-side-navbar: #808080;
$beware-message-color: #FF0000;