@import '../scss/variables.scss';

.mat-calendar-body-selected {
    background-color: $light-gray !important;
    opacity: 1 !important;
}

.disable-datepicker-view {
    visibility: hidden;
    position: absolute !important;
}

.datepicker-button {
    flex-basis: 45% !important;
    width: unset !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative !important;
}

.datepicker-close {
    right: 12px !important;
    top: 4px !important;
}

.datepicker-done {
    right: 6px !important;
}

.datepicker-close:hover {
    background: white !important;
}

.date-button {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    width: 250px;
    height: 40px !important;
    border: 1px solid $popup-no-button-border !important;
    background: inherit !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    color: $light-gray !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
}

.date-button:hover {
    border-color: $navbar-light-blue !important;
}

.fa-calendar-days {
    position: relative;
    left: 10px;
}

.date-button-span {
    position: relative !important;
    left: 25px !important;
}

.clear-icon {
    .mat-icon-button {
        width: 30px;
        height: 30px;
        background: $light-blue;
        line-height: unset;

        & .material-icons {
            font-size: 18px;
        }
    }
}

.opt-in-table-wrapper {
    border-radius: 5px;
    background: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110%;
}

.mail-top-table-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 25px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}

.opt-in-top-table-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    z-index: 1;
    gap: 2vw;
    margin: 25px;
}

.mail-table-wrapper{
    border-radius: 5px;
    height: auto;
    background: white;
    position: relative;
    top: 75px;
    max-height: 600px;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 15%;
    width: 110%;
  }