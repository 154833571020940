@import './variables.scss';

.sites-table-paginator .p-paginator.p-component,
.pages-table-paginator .p-paginator.p-component {
  background: unset;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: unset;
  position: relative;
}

.sites-table-paginator p-dropdown,
.pages-table-paginator p-dropdown {
  position: absolute;
  left: 0;
}

.sites-table-paginator p-dropdown .p-dropdown,
.pages-table-paginator p-dropdown .p-dropdown {
  border: unset;
}

.p-paginator .p-dropdown{
  margin-left: 0 !important;
}

.p-paginator-prev,
.p-paginator-next {
  border-radius: 10px !important;
  color: white !important;
}

.p-paginator-prev {
  background-color: #605BFF !important;
}

.p-paginator-next {
  background-color: #605BFF !important;
}

.mat-paginator {
    background-color: $light-blue;
}
