@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "src/app/scss/variables";
@import "./app/scss/buttons-style.scss";
@import "./app/scss/paginator-style.scss";
@import "./app/scss/table-style.scss";
@import "./app/scss/tooltip-style.scss";
@import "./app/scss/date-range-picker.scss";

* {
  font-family: Nunito, serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #F0F0F9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $popup-no-button-border;
  height: 30px !important;
  min-height: 40px;
  border-radius: 10px;

}
.monaco-editor, .monaco-diff-editor, .monaco-component {
  --vscode-scrollbarSlider-background: #C7C6FF !important;
  --vscode-focusBorder: none !important;
}

body {
  margin: 0;
  font-size: 14px;
}

.no-shadow {
  //override angular material
  box-shadow: unset !important;
}

.full-width {
  width: 100%;
}

.custom-container {
  margin-bottom: 10px;
}

.spinner {
  left: 49%;
  position: fixed !important;
  top: 15%;
  z-index: 10;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.login-container {
  height: 80vh;
}

.login-actions {
  margin-left: unset !important;
}

.mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.command-wrapper button {
  margin-left: 10px;
  margin-bottom: 5px;
}

.mat-dialog-title {
  margin: 0 !important;
}

.mat-column-actions {
  flex: 0 0 60px;
}

// .mat-table .mat-row:hover {
//   background-color: rgba(0, 0, 0, 0.075);
// }

.mat-table td.mat-cell a:hover {
  text-decoration: underline;
}

.mat-datepicker-actions {
  padding: 0 8px 16px 8px !important;
}

.mat-table td.mat-cell:first-child,
.mat-table td.mat-footer-cell:first-child,
.mat-table th.mat-header-cell:first-child {
  padding-left: 20px !important;
}

.mat-table td.mat-cell:last-child,
.mat-table td.mat-footer-cell:last-child,
.mat-table th.mat-header-cell:last-child {
  padding-right: 10px !important;
}

.mat-datepicker-content .mat-datepicker-close-button {
  display: none;
}

.mat-table .mat-cell a {
  color: inherit;
  text-decoration: none;
}

.no-records {
  padding-top: 20px;
  padding-bottom: 20px;
}

.no-records .mat-icon {
  width: 130px;
  height: 130px;
  font-size: 130px;
  color: $no-records;
}

p .mat-icon {
  vertical-align: middle;
}

.mat-slide-toggle.full-width {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mat-tooltip {
  font-size: 14px;
}

.fixed-fab {
  position: fixed !important;
  right: 2%;
  bottom: 3%;
}

/* Highlight on hover */
.submitted-timesheet-day:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
/* Highlight */
.submitted-timesheet-day>div:not(.mat-calendar-body-selected) {
  border-radius: 999px;
  background-color: rgba(14, 152, 5, 0.25);
}

.table-wrapper {
  overflow: auto;
  height: calc(100vh - 270px);
  max-height: 450px;
}

.animate {
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

/* Global styling to sites-table actions menu*/
.actions-menu {
  background: $white;
  box-shadow: 0px 10px 20px $box-shadow;
  border-radius: 10px;
  font-size: 10px;
  line-height: 14px;
  left: 10px;
  top: -10px;
  position: absolute;
  width: 135px !important;
}

.actions-menu-media {
  width: 170px !important;
}

.menu-item-override:hover {
  width: 88%;
}


.actions-menu .mat-icon {
  color: unset;
}

.actions-menu .edit {
  color: $edit-blue;
  background: $edit-blue-background;
  border-radius: 5px;
}

.actions-menu .delete {
  color: $red;
  background: $red-close-render-background;
  border-radius: 5px;
}

.search-filter .mat-form-field-appearance-outline .mat-form-field-outline-start,
.search-filter .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: unset !important;
  /* override hide border */
  background-color: $white;
  height: 40px;
}

.search-site-ctrl.mat-form-field-appearance-outline .mat-form-field-infix {
  width: 230px;
}

.filter-site-ctrl.mat-form-field-appearance-outline .mat-form-field-infix {
  width: 68px;
}

.search-filter .mat-form-field-appearance-outline .mat-form-field-infix {
  padding-top: 5px;
}

.mat-column-favorite .mat-button .mat-button-focus-overlay {
  opacity: 0 !important;
  /* override hover design of material*/
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #8F5BFF;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.success-notification-wrapper {
  background-color: $success-notification-color;
  color: white;

  .mat-button-wrapper {
    color: white;
  }
}

app-breadcrumbs {
  position: absolute;
}

// style radio button of material
.mat-radio-button.mat-primary {

  & .mat-radio-inner-circle {
      background-color: $light-gray;
  }

  &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $light-gray;
  }
}

// mat-spinner color
.spinner-color circle{
  stroke: $light-gray;
}

// position of the spinner in the content section
.spinner-wrapper {
  display: flex;
  justify-content: center;
  height: 50vh;
  align-items: center;
}
