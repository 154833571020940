@import "./variables.scss";

body,
.mat-card,
.sidenav-content,
.mat-sidenav-content,
.mat-paginator-range-actions {
    background-color: $light-blue;
}

.mat-card {
    background: none;
}

.single-cards {
    margin: 20px 0;
}

.single-card .mat-card-avatar {
    width: 50px;
    height: 50px;
}

.single-card .mat-icon {
    font-size: 55px;
}

//end dashboard

table {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: $table-font-size;
    border-collapse: collapse;
    color: $dark-blue;
}

tr {
    background: white;
}

.mat-header-row {
    background-color: $light-blue;
}

.mat-paginator {
    background-color: $light-blue;
}

.mat-header-cell {
    color: $dark-blue;
    font-size: 12px;
    opacity: 0.7;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer !important;
}

.mat-header-cell:hover {
    font-weight: 800;
}

th.mat-header-cell {
    border-bottom: unset !important;
}

tr.mat-header-row {
    height: 30px !important;
}

tr.mat-row {
    height: 70px !important;
}

.mat-table .mat-row td {
    border-bottom: unset;
}

.mat-table .mat-row {
    border: $light-blue solid;
    border-width: 10px 0 10px 0px;
    border-radius: 3px;
    transition: 0.3s all;
    cursor: pointer;
}

.mat-table .mat-row:hover {
    background-color: white;
    transform: scale(1.02);
    font-weight: bold;
}

.width-5 {
    width: 5%;
}

.width-10 {
    width: 10%;
}

.width-15 {
    width: 15%;
}

.width-20 {
    width: 20%;
}

.actions {
    position: relative;
    font-weight: bold;
    top: -0.4em;
    opacity: 0.3;
}

.mat-menu .actions-menu {
    background: $white;
    box-shadow: 0px 10px 20px $box-shadow;
    border-radius: 10px;
    font-size: 10px;
    line-height: 14px;
}

.actions-menu .edit {
    color: $edit-blue;
    background: $edit-blue-background;
    border-radius: 5px;
}

.actions-menu .delete {
    color: $red;
    background: $red-close-render-background;
    border-radius: 5px;
}

th:not([aria-sort=none]) {
    filter: drop-shadow(0px 3px 3px $drop-shadow);

}

.paginator-wrapper {
    width: 100%;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
    display: none;
}

.mat-sort-header-container.mat-sort-header-sorted {
    .mat-sort-header-arrow {
        color: $drag-drop-headers-text-color;
    }

    font-weight: 800;
}

th:not([aria-sort=none]) {
    filter: none !important;
}

.mat-sort-header-container.mat-sort-header-sorted:hover {
    .mat-sort-header-arrow {
        pointer-events: none;
    }

    pointer-events: none;
}

.render-wrapper {
    padding: 15px 15px 0 15px;
}


mat-menu-content {
    width: 135px !important;
}

.menu-action-text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: $drag-drop-headers-text-color;
    padding-left: 8px;
}

.menu-item-override {
    line-height: 0 !important;
    height: 40px !important;
}


.menu-item-override:hover {
    height: 32px;
    left: 10px;
    background: $menu-drag-drop-hover;
    border-radius: 5px;
    position: relative;
    padding: 0 6px !important;
}

.actions-menu-icons {
    color: $drag-drop-headers-icons-color;
}

.sites .sites-title {
    padding: 32px 0 15px 0;
}

.pages .sites-title {
    padding: 18px 0 15px 0;
}